import Header from '../Header'
import Footer from '../Footer'
import "../../index.css"
import "../../App.css"
// import jsonfile from '../../Parametrage/Projects/Projet_1/Project.json'
// import image1 from '../../Projet_Parametrage/Projet_1/image3.jpg'
// import image2 from '../../Projet_Parametrage/Projet_1/image2.jpg'
// import image3 from '../../Projet_Parametrage/Projet_1/image1.jpg'
// import image4 from '../../Projet_Parametrage/Projet_1/image4.jpg'
// import image5 from '../../Projet_Parametrage/Projet_1/image5.jpg'
// import image6 from '../../Projet_Parametrage/Projet_1/image6.png'
import projectList from '../../Projet_Parametrage/List_Project_To_Display.json'
import React,{ Suspense } from 'react'
import "../App/Projects.css"
import ProjectsArchives from './ProjectsArchives'
import { withTranslation } from 'react-i18next';

//Identité visuelle, Set Design, Illustration
const filters = ["All Project", "Set_Design", "Visual_Identity", "Illustration"];

class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: projectList,
      filteredData: projectList
    };
  }

  render() {

    const { data } = this.state;
    const { filteredData } = this.state;
    
  let filterArray = (filterVar) => {

    if (filterVar==="" || filterVar ==="All Project")
    {
      this.setState({ filteredData: data });
      return data;
    }
    else {
      let newArray = [];
      newArray = data.filter(function (el) {
        return (el.Type === filterVar)
      });
      this.setState({ filteredData: newArray });
      return newArray;
    }
  }

  window.addEventListener("scroll", setScrollVar);
  window.addEventListener("resize", setScrollVar);
  window.addEventListener("scrollend", setScrollEnd);


  function setScrollEnd() {
    const htmlElement = document.documentElement;
    htmlElement.style.setProperty("--textOpacity", 1);
  }

  function setScrollVar() {
    const htmlElement = document.documentElement;
    const percentOfScreeHeightScroll = htmlElement.scrollTop/ htmlElement.clientHeight; 
    console.log(htmlElement.clientWidth);
    console.log("scrollTop:" +htmlElement.scrollTop);
    console.log("clientHeight:" +htmlElement.clientHeight);

    htmlElement.style.setProperty("--scrollTop", -1*htmlElement.scrollTop );
    htmlElement.style.setProperty("--scroll", Math.min (percentOfScreeHeightScroll*100,100)+20 );
    htmlElement.style.setProperty("--coloneWidth", Math.min (((htmlElement.clientWidth-600)/600)*(htmlElement.clientWidth/6)+140,(htmlElement.clientWidth/6))+"px");
    htmlElement.style.setProperty("--clientHeight", htmlElement.clientHeight+"px");

    if (htmlElement.scrollTop ===0)
      htmlElement.style.setProperty("--textOpacity", 1);

    else
      htmlElement.style.setProperty("--textOpacity", 1);
  };

  setScrollVar();

  return (
    <Suspense fallback="loading">
      <Header data={this} />
      <div className="main" id="main">
        <div className="filters_wrapper">
          {filters.map(filter =>
              <div className="filter_wrapper">
                <button className="filter_item" variant="ghost" onClick={(event) => filterArray(filter)} > {this.props.t(filter)}</button>
              </div>
            )}
          </div>
          <ProjectsArchives data={filteredData.length > 0 ? filteredData : data} />
        </div>
        <Footer />
      </Suspense>
    );
    
  }
}
// export default Projects;
export default withTranslation()(Projects);
