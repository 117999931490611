import "../../index.css"
import "../App/AboutMe.css"
import Header from '../Header'
import Footer from '../Footer'
import React from 'react'
import { useTranslation } from 'react-i18next'

function AboutMe() {

  const { t } = useTranslation();

  return (
    <div className="main">
      <Header />
      <main className="AboutMe">
      <img className="AboutMe_Image" src={require(`../../Projet_Parametrage/Image_Profil.png`)} alt="" loading="lazy" />
      <div className="AboutMe_Text">
        <h1>About Me</h1>
        <p>
        {t("Description_AboutMe")}
        </p>
        </div>
      </main>
      <Footer />
    </div>
    
  );
}

export default AboutMe;
