import React, { Suspense } from 'react';
import "../../index.css"
import { useState } from "react"
import SwitchButton from './switchButton'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
const Header = (data) => {

    const { t } = useTranslation();
    const [showLinks, setShowLinks] = useState(false)

    const handleShowLinks = () => {
        setShowLinks(!showLinks)
    }

    return (
        <Suspense fallback="loading">

            <nav className={`navbar ${showLinks ? "show-nav" : "hide-nav"}`}>
                <div className="navbar__logo">
                <Link className="navbar__link" to="/">
                        <img className="logo_image" src={require(`../../Projet_Parametrage/Logo.png`)} alt="" loading="lazy" />
                    </Link>
                </div>
                <ul className="navbar__links">
                    <li className="navbar__item" onClick={handleShowLinks}>
                        <Link className="navbar__link" to="/">
                        &nbsp; {t('PROJECTS')}
                        </Link>
                    </li>
                    <li className="navbar__item" onClick={handleShowLinks}>
                        <Link className="navbar__link" to="/AboutMe">
                            &nbsp; {t('ABOUTME')}
                        </Link>
                    </li>
                    <li className="navbar__item" onClick={handleShowLinks}>
                        <Link className="navbar__link" to="/Contact" state={{ test: 'test' }}>
                        &nbsp; {t('CONTACT')}
                        </Link>
                    </li>
                    <li className="navbar__item">
                        <div className="switch_button" style={{
                        }}
                        >
                            <SwitchButton data={data}/>
                        </div>
                    </li>
                </ul>
                <button className="navbar__burger" onClick={handleShowLinks}>
                    <span className="burger__bar"></span>
                </button>
            </nav>
        </Suspense>

    )
}

export default Header