
import "../../index.css"
import "../../App.css"

import React from 'react'
import "../App/ProjectsArchives.css"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function ProjectsArchives(data) {

    function getRandomPlusOrMinusInt(max) {
        var plusOrMinus = Math.random() < 0.5 ? -1 : 1;
        return plusOrMinus * Math.floor(Math.random() * max);
    }

    const { t } = useTranslation();
    // function getRandomInt(max) {
    //     return Math.floor(Math.random() * max);
    // }

    // function loadImage(path){
    //     return require(path)
    // }

    return (
        <div className="archives_wrapper" >
            {data.data.map(item =>
                <div className="archives_item" style={{ "--angular_vitesse": getRandomPlusOrMinusInt(10), "--vitesse": Math.random() + 0.8 }}>
                    <div className="archives_item_wrapper">
                        <Link className="archives_links" to={{ pathname: `/${item.Title}` }} state={item}>
                            <div className="archives_item_wrapper_image" >
                                <img className={`archives_item_image ${item.MiniatureOrientation === "vertical" ? "vertical-image" : ""}`} src={require(`../../Projet_Parametrage/${item.ProjectFolder}/${item.MiniatureFile}`)} alt="" loading="lazy" />
                            </div>
                            <p className="archives_item_title" > {item.Title}</p>
                            <p className="archives_item_type" > {t(item.Type)}</p>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProjectsArchives;
