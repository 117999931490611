import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHeart, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import "../Footer/index.css"
  
const Footer = ()=>{

    return (
        <div className="footer">
            <div className="button_Wrapper">
                <li className="footer__item">
                    <Link className="footer__link" to="https://www.linkedin.com/in/louise-gauvrit-7a6413169?originalSubdomain=fr">
                        <FontAwesomeIcon className="Icon" icon={faLinkedin} />
                    </Link>
                </li>
                <li className='footer__item'>
                    <a href="mailto:louise.gauvrit@gmail.com "> <FontAwesomeIcon className="Icon" icon={faEnvelope} /></a>
                </li>
            </div>
            <div className="MadeBy_Wrapper">
                <Link className="madeby__link" to="https://www.linkedin.com/in/baptiste-dufour-734159156/"> made with <FontAwesomeIcon className="HeartIcon" icon={faHeart} /> by B. Dufour</Link>
            </div>
        </div>
    )
}

export default Footer;