import React from 'react';
import ReactDOM from 'react-dom';
import Projects from './components/App/Projects';
import AboutMe from './components/App/AboutMe';
import Contact from './components/App/Contact';
import ProjectDescription from './components/App/ProjectDescription';
// import reportWebVitals from './reportWebVitals';
import {Routes} from 'react-router-dom';
import { HashRouter, Route } from "react-router-dom";
// import { BrowserRouter as Router, Route } from "react-router-dom";

import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter >
      <Routes>
        <Route exact path="/" element={<Projects />} />
        <Route exact path="/AboutMe" element={<AboutMe />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/:title" element={<ProjectDescription />}
        />
      </Routes>
    </HashRouter>

  </React.StrictMode>,
  document.getElementById('root')
);