import "../../index.css"
import "../App/Contact.css"
import Header from '../Header'
import Footer from '../Footer'
import React from 'react'
import { useLocation } from "react-router-dom";

// import {BrowserRouter, Routes, Route, Link} from 'react-router-dom'

function Contact() {
  const state = useLocation();
  console.log(state);

  return (
    <div className="main">
      <Header />
      <main className="Contact">
      <div className="AboutMe_Text">
        <h1>Contact</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;
